<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Post Notification
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.title"
          placeholder="Subject"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
        <el-button type="primary" @click="handleCreate" style="float: right">
          <el-icon>
            <plus />
          </el-icon>
          Post Notification
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'CreatedTime', order: 'ascending' }"
      >
        <el-table-column prop="title" label="Subject"></el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          :width="this.$widthRatio * 100 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="updatedTime"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime"
          label="Updated Time"
          :width="this.$widthRatio * 120 + 'px'"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column
          label="Operation"
          align="center"
          :width="this.$widthRatio * 320 + 'px'"
        >
          <template #default="scope">
            <el-button
              v-if="scope.row.status != 'Sent'"
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
            >
              <el-icon>
                <edit />
              </el-icon>
              Edit & Post
            </el-button>
            <el-button
              type="text"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
            >
              <el-icon>
                <delete />
              </el-icon>
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>

  <el-dialog :title="dialogTitle" v-model="editVisible" width="90%">
    <post-notification
      :initData="{
        parent: this,
        isDialogMode: true,
        handleResult: this.handleResult,
        groupId: null,
        groupType: dataStorage.groupType,
        storageId: dataStorage.storageId,
        mode: this.dialogMode,
        enableToElements: true,
      }"
    />
  </el-dialog>
  <el-dialog
    :title="dialogTitle"
    v-model="showStudentSelectionDialog"
    width="85%"
  >
    <students-selection
      :initData="{ parent: this, confirmFunction: handleImportStudents }"
    />
  </el-dialog>
</template>

<script>
import { getData, deleteData } from "../../service/api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import StudentsSelection from "./StudentsSelection.vue";
import PostNotification from "./PostNotification.vue";

export default {
  name: "notificationList",
  components: { StudentsSelection, PostNotification },
  data() {
    return {
      controllerUrl: "/dataStorage",
      query: {
        storageId: null,
        title: null,
        dataType: "PostNotification",
        orderBy: "CreatedTime",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 20,
      },
      dialogMode: "NEW",
      showStudentSelectionDialog: false,
      editor: ClassicEditor,
      tableData: [],
      dataStorage: null,
      dialogTitle: "",
      editVisible: false,
      itemTotal: 0,
      pageTotal: 0,
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.initAndLoadData();
  },
  methods: {
    initAndLoadData() {
      this.loadData();
    },
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection =
          sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to delete this item?", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          deleteData(this.controllerUrl, row.storageId).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error(
                "Delete failed, error message: " + res.message
              );
            }
          });
        })
        .catch(() => {});
    },
    handleEdit(index, row) {
      this.dataStorage = row;
      this.dialogMode = "EDIT";
      this.dialogTitle = "Edit";
      this.idx = index;
      this.editVisible = true;
    },
    handleCreate() {
      this.dataStorage = {};
      this.dialogMode = "NEW";
      this.dialogTitle = "New";
      this.editVisible = true;
    },
    handleResult(action, result) {
      this.editVisible = false;
      if (action !== "CANCEL" && result === true) {
        this.loadData();
      }
    },
  },
};
</script>
<style>
.ck-editor__editable {
  min-height: 200px;
}
.el-select__input {
  min-width: auto;
  max-width: auto;
  width: 100%;
}
</style>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
